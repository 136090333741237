function toggleBodyClasses() {
  document.body.classList.toggle('overflow-hidden');
  document.body.classList.toggle('w-full');
  document.body.classList.toggle('h-full');
}

function toggleElementVisibility(id) {
  const element = document.getElementById(id);
  if (element) element.classList.toggle('hidden');
}

// Handler function for offer visibility
function handleOfferVisibility() {
  toggleBodyClasses();
  toggleElementVisibility('offer');
}

// Handler function for callme visibility
function handleCallMeVisibility() {
  toggleBodyClasses();
  toggleElementVisibility('callme');
}

// Add event listeners using a helper function
function addEventListenerToSelectors(selectors, callback) {
  selectors.forEach(selector => {
      const elements = document.querySelectorAll(selector);
      elements.forEach(element => element.addEventListener('click', callback));
  });
}

// Add event listeners
addEventListenerToSelectors(['.offer-btn', '#offer-close'], handleOfferVisibility);
addEventListenerToSelectors(['.callme-btn', '#callme-close'], handleCallMeVisibility);

// Special event listener for '#benefit-slider' due to event delegation
const benefitSlider = document.getElementById('benefit-slider');
if (benefitSlider) {
  benefitSlider.addEventListener('click', function(event) {
      if (event.target.classList.contains('offer-btn')) {
          handleOfferVisibility();
      }
  });
}
