document.addEventListener('DOMContentLoaded', function() {
  // Referenční prvky
  var fixedBar = document.getElementById('fixed-bar');
  var triggerElement = document.getElementById('fixed-bar__trigger');

  if (!fixedBar || !triggerElement) return;

  // Funkce pro ověření viditelnosti prvku
  function isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
          rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      );
  }

  // Kontrola zobrazení/skrytí prvku 'fixed-bar'
  function checkDisplay() {
      if (window.scrollY > window.innerHeight) {
          if (isElementInViewport(triggerElement)) {
              fixedBar.classList.add('hidden');
          } else {
              fixedBar.classList.remove('hidden');
          }
      } else {
          fixedBar.classList.add('hidden');
      }
  }

  // Inicializujeme kontrolu zobrazení/skrytí na začátku
  checkDisplay();

  // Event listener pro skrolování stránky
  window.addEventListener('scroll', checkDisplay);
});
