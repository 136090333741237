(function() {
  const element = document.getElementById('mail-status');
  const stopper = document.getElementById('mail-status-stopper');
  const button = element?.querySelector('button');

  // Ověření existence elementu předtím, než se pokusíme provést nějakou operaci s ním
  if (!element || !button || !stopper) return;

  let timeout = setTimeout(close, 6000);

  button.addEventListener('click', close);
  stopper.addEventListener('click', event => event.stopPropagation());
  element.addEventListener('click', close);

  function close() {
    element.remove(); // Modernější způsob odstranění elementu
    clearTimeout(timeout); // Pokud timeout neexistuje, "clearTimeout" je bezpečný a nebude házet chyby
  }
})();
