let number = 300;

const options = {
  root: null, // viewport
  rootMargin: '0px',
  threshold: 0.1, // 10% elementu musí být viditelné
};

function handleIntersect(entries, observer) {
  entries.forEach(entry => {
    if (!entry.isIntersecting) return;

    const interval = setInterval(() => {
      number++;
      if (number > 749) {
        clearInterval(interval); // Zastav interval
      }
      if (textNode) { // Ověř existence textNode
        textNode.textContent = number;
      }
    }, 6);

    observer.unobserve(entry.target); // Přestaň sledovat
  });
}

const observer = new IntersectionObserver(handleIntersect, options);

const trigger = document.getElementById('numberstart');
const textNode = document.getElementById('numbercolumn__wrap');

if (trigger) {
  observer.observe(trigger);
}
