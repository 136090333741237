document.getElementById('menu-box').addEventListener('click', function() {
  // Toggle class for body
  document.body.classList.toggle('overflow-hidden');
  document.body.classList.toggle('w-full');
  document.body.classList.toggle('h-full');

  // Toggle class for header
  const header = document.getElementById('header');
  if (header) {
    header.classList.toggle('header--open');
  }
});
